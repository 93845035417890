<template>
    <div class="event-inner-page">
        <base-nav />

        <base-inner-banner img-type="event"  />

        <b-container>
            <div class="content-wrapper">
                <div @click="leadToEvent" class="d-inline-block back">
                    <b-icon class="icon" icon="chevron-left"></b-icon>
                    <span>{{ $t('Nav_event') }}</span>
                </div>

                <base-loading class="mt-4" :is-loading="isLoading" />

                <template v-show="!isLoading">
                    <h1 class="pageTitle">{{ pageTitle }} </h1>

                    <div class="activityDate">{{ activityDate }}</div>

                    <div ref="eventContent" class="event-content w-100" v-html="content">
                    </div>
                </template>
            </div>
        </b-container>

        <div class="d-none ql-video"></div>

        <base-contact />

        <BaseFooter />
    </div>
</template>

<script>
import { getEvent } from '@/apis/apiEvents';
import { getCategories } from '@/apis/apiEventCategories';

export default {
    name: 'eventDetail',

    metaInfo() {
        return {
            title: this.$t('Nav_event') +  ' | ' + this.pageTitle,
            meta: [
                {
                    name: 'description',
                    content: this.$t('Head_description') + this.pageTitle
                } ,{
                    name: 'keywords',
                    content: this.$t('Head_keywords')
                }, {
                    name: 'author',
                    content: this.$t('Head_author')
                }, {
                    name: 'copyright',
                    content: this.$t('Head_copyright')
                }
            ]
        }
    },

    data() {
        return {
            event: null,
            category: '',
            isLoading: false,
        }
    },

    computed: {
        pageTitle() {
            const currentLang = this.$i18n.locale.includes('en') ? 'en' : 'zh';

            if (this.event) {
                return this.event[currentLang].title;
            }

            return '';
        },

        activityDate() {            
            if (this.event) {
                return this.event.activityDate;
            }

            return '';
        },

        content() {
            const currentLang = this.$i18n.locale.includes('en') ? 'en' : 'zh';

            if (this.event) {
                let content = this.event[currentLang].content;
                const re = /'/gi;
                content.replace(re, '"');
                return content;
            }

            return '';
        }
    },

    created() {
        window.scrollTo(0,0);

        const { category, eventTitle } = this.$route.params;

        this.category = category;

        if (!eventTitle) {
            this.leadToEvent();
        }

        this.initialView(eventTitle);
    },

    methods: {
        async initialView(eventTitle) {
            this.isLoading = true;
            const event = await getEvent(eventTitle);
            this.event = event;
            this.isLoading = false;
        },

        async leadToEvent() {
            const currentLang = this.$i18n.locale.includes('en') ? 'en' : 'zh';

            const categories = await getCategories();
            const firstCategory = categories[0] ? categories[0].text[currentLang]: '';
            const category = this.category || firstCategory;

            this.$router.push({
                path: `/events`,
                query: {
                    category,
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .event-inner-page{
        background: $light-gray-color;
    }

    .pageTitle{
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-top: 30px;
        line-height: 1.4em;
        color: #2c2c2c;
    }

    .activityDate {
        color: #a4a4a4;
        font-size: 18px;
        margin-top: 12px;
        letter-spacing: 0.05rem;
        padding: 0 2px;
    }

    .content-wrapper{
        max-width: 960px;
        width: 100%;
        margin:  0 auto 50px;
    }

    .back{
        color: $color-primary;
        font-size: 16px;
        margin-top:70px;
        cursor: pointer;
        .icon{
            transition: margin 0.3s ease;
            margin-right: 5px;
            display: inline-block;
        }
        &:hover {
            .icon {
                margin-right: 10px;
            }
        }
    }

    .event-content::v-deep{
        font-size: 18px;
        color: #666;
        padding: 40px 0;
        h2, h3, h4 {
            color: #222222;
            font-weight: 400;
        }
        p {
            color: #777;
            margin-bottom: 0;
        }

        p > a {
            font-size: 16px;
        }

        iframe.ql-video {
            width: 100%;
            max-width: 100%;
            height: 420px;
        }

        img {
            max-width: 100%;
            display: inline-block;
        }
    }
    
    @media screen and (max-width: 576px) {
        .pageTitle{
            font-size: 24px;
            margin-top: 18px;
        }

        .back{
            margin-top: 30px;
        }

        .activityDate{
            font-size: 16px;
        }

        .content-wrapper {
            margin: 0 auto 20px;
        }
    }
</style>